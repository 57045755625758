import axios from 'axios';
import { arcadeApiClient } from '../../../../apiClients';
import { normalizeAuth, getErrorMsg } from '../AuthHelpers';
import { ARCADE_API_ENDPOINT } from '../AuthHelpers';
export const createGroupMeSession = async ({
  accessToken,
  setAuth,
  setIsLoading,
  setErrorMsg
}): Promise<void> => {
  try {
    setErrorMsg('');
    setIsLoading(true);
    const arcadeEndpoint = `${ARCADE_API_ENDPOINT}/group_me/sessions`;
    const {
      data
    } = await axios.post(arcadeEndpoint, undefined, {
      params: {
        access_token: accessToken
      }
    }).then(normalizeAuth);
    setAuth({
      ...data,
      isGroupMe: true
    });
  } catch (err) {
    const {
      data
    } = err.response;
    console.log('GroupMe network error', err);
    const message = data?.message || getErrorMsg(data?.error);
    setIsLoading(false);
    setErrorMsg(message);
  }
};
export const registerGroupMeAccount = async ({
  accessToken,
  onSuccess,
  onError
}): Promise<void> => {
  await arcadeApiClient.post(`/group_me/registrations`, undefined, {
    params: {
      access_token: accessToken
    }
  }).then(onSuccess).catch(onError);
};